<template>
  <nut-tabs v-model="tabValue">
    <nut-tab-pane title="剩余课时">
      <nut-empty image="empty" description="无内容"></nut-empty>
    </nut-tab-pane>
    <nut-tab-pane title="已用课时">
      <nut-empty image="error" description="加载失败/错误"></nut-empty>
    </nut-tab-pane>
    <nut-tab-pane title="课时变更记录">
      <nut-empty image="network" description="无网络"></nut-empty>
    </nut-tab-pane>
  </nut-tabs>
</template>
<script setup>
import { ref } from 'vue';
const tabValue = ref(0);
</script>